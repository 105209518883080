var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"organic-begin"},[_c('div',{staticClass:"container-organic"},[_c('div',{staticClass:"organic-begin__title"},[(_vm.subtitle)?_c('hot-title',{attrs:{"tag":"p"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.subtitle)}})]):_vm._e(),_vm._v(" "),_c('hot-title',{attrs:{"tag":"h2"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}})])],1)]),_vm._v(" "),_c('div',{staticClass:"container-organic container--cards"},[(_vm.isDesktop)?_c('div',{class:['organic-begin__cards organic-begin__cards--desktop']},_vm._l((_vm.cards),function(item,i){return _c('div',{key:i,class:[
          'organic-begin__cards__item',
          `organic-begin__cards__item--${item.className}`,
          { 'with--number': item.withNumber },
        ]},[(!item.withNumber)?_c('hot-icon',{attrs:{"name":item.icon}}):_c('span',{staticClass:"organic-begin__number"},[_vm._v(_vm._s(i + 1))]),_vm._v(" "),_c('hot-title',{attrs:{"tag":"h3"}},[_c('span',{domProps:{"innerHTML":_vm._s(item.title)}})]),_vm._v(" "),_c('p',[_vm._v(_vm._s(item.description))])],1)}),0):_c('div',{class:['organic-begin__cards organic-begin__cards--mobile']},[_c('hot-carousel',{attrs:{"component":{
          is: _vm.cardBegin,
          name: 'cardBegin',
          items: _vm.cards,
        },"swiper-options":_vm.swiperOptions}})],1),_vm._v(" "),_c('div',{staticClass:"organic-begin__cta"},[_c('hot-organic-button',_vm._b({attrs:{"href":"javascript:void(0);"},on:{"click":function($event){$event.preventDefault();return _vm.openModal.apply(null, arguments)}}},'hot-organic-button',_vm.cta,false),[_vm._v("\n        "+_vm._s(_vm.cta.label)+" "),_c('hot-icon',{attrs:{"name":"OrganicArrowRightLink"}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }