export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  data() {
    return {}
  },

  props: {
    withNumber: {
      type: Boolean,
      required: false,
    },

    number: {
      type: String,
      required: false,
    },

    icon: {
      type: String,
    },

    description: {
      type: String,
    },

    title: {
      type: String,
    },

    className: {
      type: String,
    },
  },

  mounted() {},

  beforeDestroy() {},

  watch: {},

  methods: {},
}
