import { render, staticRenderFns } from "./Begin.vue?vue&type=template&id=0e3fd1ae&scoped=true"
import script from "./Begin.js?vue&type=script&lang=js&external"
export * from "./Begin.js?vue&type=script&lang=js&external"
import style0 from "./Begin.scss?vue&type=style&index=0&id=0e3fd1ae&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e3fd1ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotTitle: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Title/Title.vue').default,HotOrganicButton: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Organic/Button/Button.vue').default})
