import CardBegin from '~/components/Molecules/Organic/CardBegin/CardBegin.vue'
import hotOrganicButton from '~/components/Atoms/Organic/Button/Button.vue'
import modalSignup from '~/mixins/modalSignup.js'
import device from '~/mixins/device.js'

export default {
  mixins: [modalSignup, device],

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
    },

    cards: {
      type: Array,
      required: true,
    },

    cta: {
      type: Object,
    },
  },

  components: {
    hotOrganicButton,
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  data() {
    return {
      cardBegin: CardBegin,
      swiperOptions: {
        spaceBetween: 5,
        allowTouchMove: true,
        normalizeSlideIndex: false,
        centeredSlides: false,
        breakpoints: {
          320: {
            slidesPerView: 2.03,
          },
        },
      },
    }
  },
}
